import React from "react";
import Slider from "react-slick";
import IacImage from "../../static/images/iac.jpg";
import ThreeSixtyTwoImg from "../../static/images/landing_slider_2.jpg";
import ThreeSixtyThreeImg from "../../static/images/landing_slider_3.jpg";
import CenterIcon360 from "../../static/svg/Responsive.svg";
import VideoIcon from "../../static/svg/Page-1.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./homeSlider.css";

function HomeSlider() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,
    initialSlide: 0,
    adaptiveHeight: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="container">
      {/* <h2> #040F1F </h2> */}
      <div className=" gx-3 " style={{ backgroundColor: "#040F1F" }}>
        <Slider {...settings}>
          <div
            className=" position-relative slide-two"
            style={{ width: "100%" }}
          >
            <img
              src={IacImage}
              className=" img-responsive"
              alt=""
              style={{ width: "100%", borderRadius: "9px " }}
            />
            {/* <img
                    src={VideoIcon}
                    alt=""
                    className="position-absolute p-1  top-0 end-0 videIcon"
                    id="videoIcon"
                    
                  /> */}
            <a href="/tour/1" target="_blank">
              <img
                src={CenterIcon360}
                className="position-absolute img-responsive p-1 top-50 start-50 translate-middle center360Icon"
                alt=""
              />
            </a>
            <div
              className=" position-absolute start-0 bottom-0  text-white  textSliderCards"
              style={{ fontSize: "14px" }}
            >
              Institute for Art and Culture
            </div>
          </div>

          <div className="col-md-4 ">
            <div className="position-relative slide-two">
              <img
                src={ThreeSixtyTwoImg}
                className="img-responsive "
                alt=""
                style={{ width: "100%", borderRadius: "9px" }}
              />
              {/* <img
                  src={VideoIcon}
                  alt=""
                  className="position-absolute p-1  top-0 end-0 videIcon"
                /> */}
              <img
                src={CenterIcon360}
                className="position-absolute img-responsive p-1 top-50 start-50 translate-middle center360Icon"
                alt=""
              />
              <div
                className=" position-absolute start-0 bottom-0 text-white  textSliderCards "
                style={{ fontSize: "14px" }}
              >
                Punjab Lahore Eden City
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 ">
            <div className=" position-relative slide-two">
              <img
                src={ThreeSixtyThreeImg}
                className="img-responsive "
                alt=""
                style={{ width: "100%", borderRadius: "9px" }}
              />
              {/* <img
                  src={VideoIcon}
                  alt=""
                  className="position-absolute p-1  top-0 end-0 videIcon"
               
                /> */}
              <img
                src={CenterIcon360}
                className="position-absolute img-responsive p-1 top-50 start-50 translate-middle center360Icon"
                alt=""
              />
              <div
                className="position-absolute start-0 bottom-0 text-white  textSliderCards"
                style={{ fontSize: "14px" }}
              >
                Punjab Lahore Eden City
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default HomeSlider;
