import React from "react";
import facebook from "../../static/svg/facebook.svg";
import instagram from "../../static/svg/instagram.svg";
import twitter from "../../static/svg/twitter.svg";
import pinterest from "../../static/svg/pinterest.svg";
import youtube from "../../static/svg/youtube.svg";
import footerIconEvo from "../../static/svg/footerEvo.svg";
import "./footer.css";
const Footer = () => {
  return (
    <div className="footerContainer">
      <hr className=" m-auto " id="" />
      <div
        class="d-flex flex-row bd-highlight    "
        id="bd-highlight"
        style={{ width: "100%" }}
      >
        <div
          className="ms-4  pt-2 bd-highlight  text-start"
          style={{ width: "18%" }}
          id="sectionOne"
        >
          <a href="https://www.google.com">
            <img src={facebook} alt="" height="16" id="iconFace" />
          </a>
          <a href="">
            <img src={twitter} alt="" className="ps-3" id="icon1" />
          </a>
          <a href="">
            <img src={instagram} alt="" className="ps-3" id="icon1" />
          </a>
          <a href="">
            <img src={pinterest} alt="" className="ps-3" s id="icon1" />
          </a>
          <a href="">
            <img src={youtube} alt="" className="ps-3" id="icon1" />
          </a>
        </div>

        <hr className="m-auto" id="hrDesktop" />

        <div class="p-2 bd-highlight" id="sectionTwo"></div>
        <div
          class="p-2 bd-highlight me-4 text-end"
          id="sectionTwo"
          style={{ width: "32%" }}
        >
          <span
            className=" ms-2"
            style={{ fontSize: "12px", color: "#A4BAC6" }}
          >
            Terms & Condition | Privacy Policy
          </span>

          <img
            src={footerIconEvo}
            alt=""
            style={{ width: "40px" }}
            className="ms-2 logoFooter"
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
