import React from "react";

import loading from "../../static/gifs/loading.gif";

const Loading =()=>{
    return (
      <div
        id="loading"
        style={{
          display: "flex",
          zIndex: "99999",
          position: "fixed",
          top: "0px",
          width: "100%",
          height: "100%",
          right:'0',
          backgroundColor: "rgba(0,0,0,0.5)",
          flexDirection:'column',
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={loading} alt="Loading" width="70px" height="70px"/><br/>
        <p className="text-center text-white">Loading...</p>
      </div>
    );
  }
export default Loading;
