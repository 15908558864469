import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import ivisitLogo from "../../static/svg/ivisit-new-logo.svg";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import Footer from "../Footer/Footer";
const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isGoogleLogin, setGoogleLogin] = useState(false);
  const history = useHistory();

  useEffect(() => {
    document.body.style.backgroundColor = "#040F1F";
  });
  const [error, setError] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    zipCode: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    } else if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "zipCode") {
      setZipCode(value);
    }
    let errors = error;
    switch (name) {
      case "email":
        if (!value.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")) {
          //regex for alphabets only
          errors.email = "Email is not valid";
        } else errors.email = "";
        break;
      case "firstName":
        if (!value.match(/^[A-Za-z]+$/)) {
          //regex for alphabets only
          errors.firstName = "Only alphabets are allowed";
        } else if (value === "") {
          errors.firstName = "Name can't empty";
        } else errors.firstName = "";
        break;
      case "lastName":
        if (!value.match(/^[A-Za-z]+$/)) {
          //regex for alphabets only
          errors.lastName = "Only alphabets are allowed";
        } else errors.lastName = "";
        break;
      case "password":
        if (!value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/))
          errors.password =
            "At least one uppercase letter, one lowercase letter and one number";
        else errors.password = "";
        break;
      // case "zipCode":
      // if (!value.match(^([a-zA-Z0-9][\s\\/-]*){8}$))
      //   errors.password =
      //     "ZipCode is not valid";
      // else errors.password = "";
      // break;
      default:
        break;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      userEmail: email,
      password: password,
      first_name: firstName,
      last_name: lastName,
      zipcode: zipCode,
      role: "user",
    };
    axios
      .post("https://ivisit-tour.azurewebsites.net/user-auth/signUp", data)
      .then((res) => {
        const functionThatReturnPromise = () =>
          new Promise((resolve) => setTimeout(resolve, 3000));
        toast.promise(functionThatReturnPromise, {
          pending: "Promise is pending",
          success: "User register successfully",
          error: "User Not register successfully🤯",
        });
        history.push("/signin");
      })
      .catch((err) => {
        console.log(err.response.data.message);
        const functionThatReturn = () =>
          new Promise((resolve) => setTimeout(resolve, 3000));
        toast.promise(functionThatReturn, {
          pending: "Promise is pending",
          error: "",
          success: err.response.data.message + "🤯",
        });
      });
  };

  const responseGoogle = (response) => {
    setGoogleLogin(true);
    setEmail(response.profileObj.email);
    setPassword(response.profileObj.googleId);
    setLastName(response.profileObj.familyName);
    setFirstName(response.profileObj.givenName);

    console.log(response);
  };


  return (
    <div
      style={{ backgroundColor: "#040F1F", minHeight: "100vh" }}
      className=" d-flex flex-column "
    >
      <div style={{ flex: "1 0 auto" }}>
        <ToastContainer />
        <Container className="mt-5">
          <Row className="row align-items-start mt-5">
            <Col className="col-sm-offset-5 mt-1  justify-content-center text-center ">
              <img
                className="login-logo"
                src={ivisitLogo}
                alt="logo"
                height="85px"
                width="240"
              />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center ">
            <Col
              className="bgLogin mt-3 p-4"
              xl={5}
              lg={5}
              md={5}
              sm={7}
              xs={12}
              style={{ backgroundColor: "#040F1F", borderRadius: "5px" }}
            >
              <h2 className="loginText text-center text-white mt-4">Sign Up</h2>
              <Form onSubmit={handleSubmit} className="mt-4">
                <Row className="d-flex justify-content-center">
                  <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={11}
                  >
                      <Form.Control
                        id="firstName"
                        value={firstName}
                        type="text"
                        placeholder="First Name"
                        className="p-3 inputText"
                        name="firstName"
                        disabled={isGoogleLogin}
                        onChange={handleChange}
                        required
                      />
                      {error.firstName && <span id="labelmsg " className="text-start" style={{ color: "red", width: "" }}>
                      {error.firstName}
                    </span>}
                  </Col>

                  <Col
                    className="marginMbTop"
                    lg={6}
                    md={6}
                    sm={6}
                    xs={11}
                  >
                      <Form.Control
                        id="lastName"
                        type="text"
                        placeholder="Last Name"
                        className="p-3 inputText"
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        disabled={isGoogleLogin}
                        required
                      />
                    {error.lastName &&
                    <span id="labelmsg " className="text-start" style={{ color: "red", width: "" }}>
                      {error.lastName}
                    </span>
                    }

                  </Col>
                  {/* <Col lg={11} md={11} sm={10} xs={11}>
                    <Form.Group className="mb-3" controlId="formBasicZipCode">
                      <Form.Label className="inputLabel">ZipCode</Form.Label>
                      <Form.Control
                        id="zipCode"
                        type="text"
                        placeholder="Zip code"
                        className="p-3 inputText"
                        name="zipCode"
                        value={zipCode}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col> */}
                </Row>
                <Row className="d-flex justify-content-center mt-3">
                  <Col lg={12} md={12} sm={10} xs={11}>
                    <Form.Group className="mb-2 ">
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        className="p-3 inputText"
                        name="email"
                        value={email}
                        disabled={isGoogleLogin}
                        onChange={handleChange}
                      />
                      <p id="labelmsg" style={{ color: "red" }}>
                        {error.email}
                      </p>
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12} sm={10} xs={11}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control
                        id="password"
                        type="password"
                        placeholder="Password"
                        className="p-3 inputText"
                        name="password"
                        value={password}
                        disabled={isGoogleLogin}
                        onChange={handleChange}
                      />
                      <p id="labelmsg" style={{ color: "red" }}>
                        {error.password}
                      </p>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={10} xs={11} className="m-auto">
                    <div className="d-flex justify-content-center ">
                      <Button
                        type="submit"
                        size="md"
                        className="m-auto  loginBtn w-100 p-2 fs-5"
                        style={{ backgroundColor: "#14B8E1" }}
                      >
                        Sign Up
                      </Button>
                    </div>
                    <div className="justify-content-center m-auto mt-3">
                      <GoogleLogin
                        className=" googleLogin w-100 justify-content-center     {
                      
                    }"
                        clientId="952843987121-67llt22greg5mgjpm06lja29j5ohllmr.apps.googleusercontent.com"
                        buttonText="Continue with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                    </div>
                  </Col>
                </Row>
                {/* <p className="text-center text-reset mt-3 fs-5">Or</p>
                <div className="d-flex justify-content-center ">
                  <Button
                    type="submit"
                    size="md"
                    className=" p-md-2 btn-info fs-5 text-reset btnSignin"
                    style={{ width: "70%", color: "white" }}
                    onClick={() => history.push("/signin")}
                  >
                    Signin
                  </Button>
                </div> */}
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(SignUp);
