import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ivisitLogo from "../../static/svg/ivisit-new-logo.svg";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { useHistory, withRouter, Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./styleCombine.css";
import Footer from "../Footer/Footer";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ email: "", password: "" });
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email,
      password: password,
      role: "user",
    };
    axios
      .post("https://ivisit-tour.azurewebsites.net/user-auth/login", data)
      .then((res) => {
        const functionThatReturnPromise = () =>
          new Promise((resolve) => setTimeout(resolve, 3000));
        toast.promise(functionThatReturnPromise, {
          pending: "Promise is pending",
          success: "User Login successfully",
          error: "User Not register successfully🤯",
        });
      })
      .catch((err) => {
        console.log(err.response.data.message);
        const functionThatReturn = () =>
          new Promise((resolve) => setTimeout(resolve, 3000));
        toast.promise(functionThatReturn, {
          pending: "Promise is pending",
          error: "",
          success: err.response.data.message + "🤯",
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
    let errors = error;
    switch (name) {
      case "email":
        if (!value.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")) {
          //regex for alphabets only
          errors.email = "Email is not valid";
        } else errors.email = "";
        break;
      case "password":
        if (!value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/))
          errors.password =
            "At least one uppercase letter, one lowercase letter and one number";
        else errors.password = "";
        break;

      default:
        break;
    }
  };
  const responseGoogle = (response) => {
    setEmail(response.profileObj.email);
    setPassword(response.profileObj.googleId);
  };
  return (
    <div
      className=" d-flex flex-column "
      style={{ backgroundColor: "#040F1F", minHeight: "100vh" }}
    >
      <div style={{ flex: "1 0 auto" }}>
        <Container fluid className="mt-5">
          <ToastContainer />
          <Row className="row align-items-start pt-4">
            <Col className="col-sm-offset-5 justify-content-center text-center ">
              <img
                className="login-logo img-responsive "
                src={ivisitLogo}
                alt="logo"
                height="90px"
                width="210"
              />
            </Col>
            <Row className="justify-content-center p-4  ">
              <Col
                className="bgLogin p-4 ms-4 "
                xxl={3}
                lg={4}
                md={6}
                sm={7}
                xs={12}
                style={{ backgroundColor: "#040F1F" }}
              >
                <h2 className="loginText text-white text-center">Log In</h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="pt-3">
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      className="p-3 inputText"
                      name="email"
                      value={email}
                      onChange={handleChange}
                    />
                    <p id="labelmsg" style={{ color: "red" }}>
                      {error.email}
                    </p>
                  </Form.Group>
                  <Form.Group className="mt-4" controlId="formBasicPassword">
                    <Form.Control
                      id="password"
                      type="password"
                      value={password}
                      name="password"
                      placeholder="Password"
                      className="p-3 inputText"
                      onChange={handleChange}
                    />
                    <p id="labelmsg" style={{ color: "red" }}>
                      {error.password}
                    </p>
                  </Form.Group>
                  <div className="d-flex justify-content-center ">
                    <Button
                      type="submit"
                      size="md"
                      className="p-2 m-auto  w-100 loginBtn "
                      style={{ backgroundColor: "#14B8E1" }}
                    >
                      Login
                    </Button>
                  </div>
                  <div className="p-1 mt-4">
                    <GoogleLogin
                      className="googleLogin w-100 justify-content-center fw-3 "
                      clientId="952843987121-67llt22greg5mgjpm06lja29j5ohllmr.apps.googleusercontent.com"
                      buttonText="Login with Google"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  </div>

                  <div
                    className="d-flex  justify-content-center mt-3"
                    style={{ fontSize: "14px", color: "#A4BAC6" }}
                  >
                    Are you a new Visitor?
                  </div>
                  <div className="p-1 mt-2">
                    <Button
                      type="submit"
                      size="md"
                      onClick={() => history.push("/signup")}
                      className="p-2 m-auto justify-content-center fw-3  w-100 googleLogin "
                      style={{ backgroundColor: "#14B8E1" }}
                    >
                      Sign Up Now
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(SignIn);
