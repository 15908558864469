import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
const AnimatedLogo = () => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: require("./logo-ivisit.json"),
    });

    return () => {
      lottie.destroy();
    };
  }, []);
  return (
    <div
      className="navbarTourLogo ms-4"
      ref={container}
      onMouseEnter={() => lottie.play()}
      onMouseLeave={() => lottie.goToAndStop(30)}
    />
  );
};

export default AnimatedLogo;
