import React, { useState } from "react";
import CenterIcon360 from "../../static/svg/Responsive.svg";
import VideoIcon from "../../static/svg/Page-1.svg";
import LikeButton from "../Tours/LikeButton";

import "./tourImage.css";
import constants from "../../utils/constants";

const TourImage = ({ item, searchRecord }) => {
  const [toggle, setToggle] = useState(true);

  const clickLikeButton = () => {
    if (toggle === true) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };

  return (
    <div
      id="0"
      className="card__IconBg cardsvg__icon"
      style={{ transform: "translate(0px, 0px)", opacity: "1" }}
    >
      {/* <div
          style={{
            position: "absolute",
            zIndex: "10",
            top: "10px",
            width: "100%",
          }}
        >
          <div className="d-flex justify-content-between px-2">
            <div>
              <div className="position-absolute img-responsive p-1 top-50 start-50 translate-middle ">
                <a href={`/tour/${item.id}`} target="_blank">
                  <img
                    src={CenterIcon360}
                    alt=""
                    style={{
                      transform: "translate(0%,294%)",
                      width: "37px",
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="">
             <img src={VideoIcon} alt="" style={{ width: "28px" }} />
            </div>
          </div>
        </div> */}
      <div className="d-flex cardHeadingName cardHeadingFontSize">
        <div
          style={{
            position: "absolute",
            bottom: "2%",
            left: "2%",
            zIndex: 1,
          }}
        >
          {item.Name}
        </div>
        {/* <div className="ms-auto">
            <div className="ms-auto" onClick={clickLikeButton}>
              <LikeButton name={toggle ? "likeSvg" : "likeSvgClick"} />
            </div>
          </div> */}
        <img
          className="backgroundImage"
          src={constants.API.CDN_URL + item.ThumbnailUrl}
        />
      </div>
      <div>
        {/* <div
            className="backgroundImage"
            role="button"
            tabIndex="0"
            style={{
              cursor: "pointer",
              backgroundImage: `url('${
                constants.API.CDN_URL + item.ThumbnailUrl
              }')`,
              transform: "translate(0%, 0px)",
              width: "100%",
            }}
          /> */}
      </div>
    </div>
  );
};

export default TourImage;
