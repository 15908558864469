import React from "react";
import loading from "../../static/gifs/loading.gif";

export default function HtmlLoading() {
	return (
		<div
			id='loading'
			style={{
				display: "flex",
				zIndex: "100",
				position: "fixed",
				top: "0px",
				width: "100%",
				height: "100%",
				backgroundColor: "rgba(0,0,0,1)",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
			}}
		>
			<img src={loading} alt='Loading' height='100px' />
		</div>
	);
}
