import React, { useState, useEffect, useRef } from "react";
import {
  Navbar,
  Nav,
  FormControl,
  InputGroup,
  Container,
  Dropdown,
} from "react-bootstrap";
import constants from "../../utils/constants";
import axios from "axios";
import iconEye from "../../static/svg/icon-eye.svg";
import searchIcon from "../../static/svg/searchIcon.svg";
import MenuButtons from "./MenuButtons";
import TourSidebar from "./TourSidebar";
import SearchBarMobile from "./SearchBarMobile";
import TourImage from "./TourImage";
import Footer from "../Footer/Footer";
import Loading from "../Homepage/Loading";
import "./tours.css";
import ProfileUserDropDown from "./ProfileUserDropDown";
import AnimatedLogo from "./AnimatedLogo";
import FilterDropdown from "./FilterDropdown";

const Tours = (props) => {
  const [allData, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [filter, setFilter] = useState([]);
  const [show, setShow] = useState(false);
  const inputRef = useRef("");
  const [value, onChange] = useState(new Date());

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    axios
      .get(`${constants.API.CREATORAPI}space`)
      .then((res) => {
        setData(res.data);
        setFilter(res.data);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err, "Data not fetch");
      });
  }, []);

  const searchHandler = (searchTerm) => {
    console.log(searchTerm);
    setSearchTerm(searchTerm);
    if (searchTerm !== "") {
      const collageList = allData.filter((allCollage) => {
        return Object.values(allCollage)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchResult(collageList);
      setFilter(collageList);
    } else {
      setSearchResult(allData);
      setFilter(allData);
    }
  };
  const getSearchTerm = (event) => {
    searchHandler(event.target.value);
  };
  const filterCategory = (category) => {
    if (category !== 0) {
      const eduction = allData.filter((edu) => {
        return edu.CategoriesId === category;
      });
      setFilter(eduction);
    } else {
      setFilter(allData);
    }
  };

  return (
    <div className>
      <div style={{ backgroundColor: "#051a38" }} className="min-vh-100">
        <Navbar
          collapseOnSelect
          expand="lg"
          style={{ backgroundColor: "#09254E" }}
          className="navbarStyle sticky-top"
        >
          <Container fluid className="sticky-top">
            {/* <Navbar.Toggle
              aria-controls="responsive-navbar-nav toggleIcon"
              id="toggleBtn"
              onClick={handleShow}
            /> */}
            <Navbar.Brand href="/">
              <AnimatedLogo />
            </Navbar.Brand>

            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="d-none d-md-none d-lg-block "
            >
              <Nav
                className="d-flex justify-content-center m-auto "
                style={{ minWidth: "56%", position: "relative", right: "40px" }}
              >
                <InputGroup className="m-auto">
                  <FormControl
                    type="text"
                    placeholder="Search"
                    aria-label="Input group example"
                    className="headSearchInputField"
                    aria-describedby="btnGroupAddon2"
                    ref={inputRef}
                    onChange={getSearchTerm}
                  />
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                    style={{
                      backgroundColor: "#040f1f",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    <img src={searchIcon} width="27" alt="search" />
                  </span>
                </InputGroup>
              </Nav>
            </Navbar.Collapse>
            {/* <Nav>
              <Nav.Link eventKey={2} href="#memes">
                <ProfileUserDropDown />
              </Nav.Link>
            </Nav> */}
          </Container>
          {/* <TourSidebar
            show={show}
            handleShow={handleClose}
            filterCategory={filterCategory}
          /> */}
        </Navbar>
        <div className="filterSearchBox d-lg-none d-md-block " style={{}}>
          <div className="filterComponent">
            <SearchBarMobile getSearchTerm={getSearchTerm} />
          </div>
        </div>
        <div
          className="d-flex flex-column mb-3"
          style={{ backgroundColor: "#040F1F" }}
        >
          <div className="headerText  mt-5 mb-5 m-auto">
            <div className="headH1 text-white ">
              EXPERIENCE 360<sup>o</sup>
            </div>
            <div className="headP text-center mb-2">
              Your Gateway to the World
            </div>
          </div>
          <br />
        </div>
        {/* Menu Button */}
        {/* <MenuButtons filterCategory={filterCategory} /> */}

        <div className="container-fluid">
          <div className="row">
            <div className="col-12  m-auto">
              <div className="eyeMostVoi d-flex align-items-center">
                <div className="text-white  mt-4 d-flex d-none d-md-none d-lg-block text-center">
                  {/* <div className="d-flex ">
                    <span className="d-flex ms-3 ">
                      <img src={iconEye} alt="" className=" pb-2" />
                    </span>
                    <p
                      className="ps-2 pt-2"
                      style={{ fontSize: "13px", fontWeight: "700" }}
                    >
                      Most View
                    </p>
                  </div> */}
                </div>

                {/* Dropdown filter button */}

                {/* <FilterDropdown /> */}
              </div>
            </div>
            <div className="row m-auto mt-auto">
              <div className="col-12">
                {load ? (
                  <Loading className="mt-5" />
                ) : (
                  <div className="explore-flexpanel ">
                    <div className="flexpanel-section mb-3 ">
                      {/* {filter.map((item) => (
                      <div className="flexpanel-item-college">
                        <TourImage item={item} />
                      </div>
                    ))} */}
                      {filter.length > 0 ? (
                        filter.map((item) => (
                          <a
                            href={`/tour/${item.id}`}
                            target="_blank"
                            key={item.id}
                            className="flexpanel-item-college"
                          >
                            <TourImage item={item} searchRecord={searchTerm} />
                          </a>
                        ))
                      ) : (
                        <div className="d-flex justify-content-center m-auto mt-5">
                          <h2 className="text-white  ">Record not found...</h2>
                        </div>
                      )}

                      {/* {filter.map((item) => (
                    <div className="flexpanel-item-college">
                      <CollegeExperience item={item} />
                    </div>
                  ))}
                  {filter.map((item) => (
                    <div className="flexpanel-item-college">
                      <CollegeExperience item={item} />
                    </div>
                  ))} */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="d-none d-md-none d-lg-block  " style={{height:'50px'}}>
        <Footer />
      </div> */}
        </div>
      </div>
    </div>
  );
};

export default Tours;
