import React, { useState } from "react";
import Calendar from "react-calendar";
import {
  FormControl,
  InputGroup,
  Dropdown,
  Col,
  Row,
  Form,
} from "react-bootstrap";
import iconFilter from "../../static/svg/filter.svg";

import "./searchBarMobile.css";
const SearchBarMobile = ({getSearchTerm}) => {
  const [value, onChange] = useState(new Date());
  function scrollDisplay() {
    const body = document.getElementsByTagName("body")[0];
    body.classList.toggle("noscroll");
  }
  return (
    <div className=" ">
      <Dropdown className="d-inline m-auto" autoClose={false}>
        <InputGroup className="mt-2 " id="seaarchFiled">
          <FormControl
            type="text"
            placeholder="Search"
            aria-label="Input group example"
            className="headMSearchInputField  "
            aria-describedby="btnGroupAddon2"
            onChange={getSearchTerm}
          />
          <InputGroup.Text id="btnGroupAddon2" className="searchMIconField">
            <Dropdown.Toggle
              id="dropdown-button-dark-example1 toggle"
              className="text-white btn dropdownSearchBtn  ms-auto  d-flex  "
              style={{
                border: "none",
                backgroundColor: "transparent",
              }}
            >
              <img
                src={iconFilter}
                alt=""
                width="20px"
                onClick={scrollDisplay}
              />
            </Dropdown.Toggle>
          </InputGroup.Text>
        </InputGroup>

        <Dropdown.Menu id="mobileFilterDropdown" className="">
          <Row className="totalSpace">
            <div className="text-white text-start layoutFilterXs ms-4 mt-3">
              Filter Results
            </div>
            <Col className="mt-3  layoutXs" xs={12} md={6} sm={6}>
              <div className="calendar ">
                <Calendar
                  id="calendarStyle"
                  onChange={onChange}
                  value={value}
                  tileClassName="text-white "
                  tileDisabled={({ activeStartDate, date, view }) =>
                    date.getMonth() !== new Date().getMonth()
                  }
                />
              </div>
            </Col>
            <Col md={6} sm={6} className=" layoutXs">
              <Row className="ms-1 ">
                <Col sm={12}>
                  <Form.Select id="cities" size="sm" className="selectCity">
                    <option value="">City</option>
                    <option value="lhr">Lahore</option>
                    <option value="fsd">Faisalabad</option>
                    <option value="isb">Islamabad</option>
                    <option value="khr">Karachi</option>
                  </Form.Select>
                </Col>
                <Col className="" sm={12}>
                  <div
                    className="text-start mt-2 "
                    style={{ fontSize: "18", color: "#A4BAC6" }}
                  >
                    Event Type
                  </div>
                  <div className=" mt-3">
                    <Form className="text-start ">
                      {["checkbox"].map((type) => (
                        <div key={`inline-${type}`} className="d-flex">
                          <Form.Check
                            inline
                            label="Indoor"
                            name="Indoor"
                            className="checkBoxFilterMobile "
                            type={type}
                            style={{ fontSize: "18", color: "#A4BAC6" }}
                            id={`inline-${type}-1`}
                          />
                          <Form.Check
                            inline
                            label="Indoor"
                            className="checkBoxFilterMobile   ms-auto "
                            style={{ fontSize: "18", color: "#A4BAC6" }}
                            name="Indoor"
                            type={type}
                            id={`inline-${type}-2`}
                          />
                        </div>
                      ))}
                    </Form>
                  </div>
                  <div className="visit-filter">
                    <input
                      type="button"
                      value="Filter Result"
                      className="btn btnFilterMobile "
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SearchBarMobile;
