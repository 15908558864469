import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignIn from "./components/Login/SignIn";
import SignUp from "./components/Login/SignUp";
import "react-toastify/dist/ReactToastify.css";
import Home from "./components/Homepage/Home";
import Tours from "./components/Tours/Tours";
import HtmlLoading from "./components/VirtualTour/HtmlLoading";
import LoadingScreens from "./components/VirtualTour/LoadingScreens";

const Viewer = React.lazy(() => import("./components/VirtualTour/Viewer"));
const BIHViewer = React.lazy(() =>
  import("./components/VirtualTour/BIHViewer")
);

function Routes() {
  return (
    <Router>
      <Suspense fallback={<LoadingScreens />}>
        <Switch>
          <Route exact path="/">
            <Tours />
          </Route>
          {/* <Route exact path="/signin">
            <SignIn />
          </Route>
          <Route exact path="/signup">
            <SignUp />
          </Route>
          <Route exact path="/tours">
            <Tours />
          </Route> */}
          <Route exact path="/tour/:id">
            <Viewer />
          </Route>
          {/* <Route exact path="/view/bih">
            <BIHViewer />
          </Route> */}
          <Route path="*" element={<h1>404 Page</h1>} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default Routes;
