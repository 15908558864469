import { useLocation } from "react-router-dom";
import BIHLoading from "./BIHLoading";
import HtmlLoading from "./HtmlLoading";

export default function LoadingScreens() {
	const location = useLocation();

	console.log(location.pathname);

	if (location.pathname === "/view/bih") {
		return <BIHLoading />;
	} else {
		return <HtmlLoading />;
	}
}
