const constants = {
  API: {
    BASEURL: "https://ivisit-tour.azurewebsites.net/",
    // BASEURL: "http://localhost:5000/",
    CREATORAPI: "https://ivisit-api.azurewebsites.net/",
    CDN_URL: "https://ivisit-cdn.azureedge.net/ivisit-media/",
  },
};

export default constants;
